import {useCallback, useRef, useState} from 'react'
import {useRouter} from 'next/router'
import {useSelector, useDispatch} from 'react-redux'
import clsx from 'clsx'
import AppButton from 'src/components/elements/buttons/AppButton'
import AppIcon from 'src/components/elements/icons/AppIcon'
import LocationSearchBox from 'src/components/modules/locationSearchBox/LocationSearchBox'
import SearchAmenities from 'src/components/modules/home/homeHero/searchComponents/SearchAmenities'
import SearchDate from 'src/components/modules/home/homeHero/searchComponents/SearchDate'
import GuestsMenu from './GuestsMenu'
import {
  setSearchPageNo,
  setCurrentMapZoom,
  fetchSearchData,
  setScrollY,
  setClickedPropertyUid,
} from 'src/store/actions/propertySearchActions'
import {getSelectedLocation} from 'src/store/selectors/propertySearchSelectors'
import {
  useBreakpoint,
  useLargeSize,
  useMediumSize,
  useSmallSize,
} from 'src/hooks/screenSize'
import {SEARCH_RESULT_PAGE, SEARCH_RESULT_PAGE_EN} from 'src/constants/route'
import {getSearchUrl} from 'src/utils/search'
import {
  INITIAL_ZOOM_LEVEL,
  LOCATION_PROPERTY_ZOOM_LEVEL,
  LOCATION_ZOOM_LEVEL,
} from 'src/constants/googleMap'
import {useCurrentLocale} from 'src/hooks/locale'
import {LOCALE_EN} from 'src/constants/locale'
import {getSearchColumnLocation} from 'src/constants/property'
import {useSearchStyles} from './styles'
import {useTranslation} from 'next-i18next'
import Hidden from 'src/components/helpers/Hidden'

export default function SearchBox(props: {
  inTopBar?: boolean
  defaultSearchValue?: string | null
}) {
  const {inTopBar, defaultSearchValue} = props

  const isSmallSize = useSmallSize()
  const classes = useSearchStyles({inTopBar, isSmallSize})
  const router = useRouter()
  const dispatch = useDispatch()
  const inputLocationRef = useRef<HTMLInputElement>(null)
  const currentLocale = useCurrentLocale()
  const searchPage =
    currentLocale === LOCALE_EN ? SEARCH_RESULT_PAGE_EN : SEARCH_RESULT_PAGE

  const selectedLocation = useSelector(getSelectedLocation)
  const breakpoint = useBreakpoint()
  const {t} = useTranslation('common')

  const [enlargeBtn, setEnlargeBtn] = useState<boolean>(false)

  const onOpen = useCallback((bool: boolean) => {
    if (bool) {
      setEnlargeBtn(bool)
    }
  }, [])

  const handleSearch = async () => {
    dispatch(setSearchPageNo(1))

    const preDefinedValue =
      selectedLocation && selectedLocation.address
        ? getSearchColumnLocation(selectedLocation.address.toString(), t)
        : null

    if (preDefinedValue) {
      if (breakpoint) {
        dispatch(setCurrentMapZoom(preDefinedValue.options[breakpoint][0]))
      }
    } else {
      if (
        selectedLocation?.autocompleteLocation &&
        (selectedLocation?.autocompleteLocation.type === 'mrc' ||
          selectedLocation?.autocompleteLocation.type === 'administrative_area')
      ) {
        dispatch(setCurrentMapZoom(LOCATION_ZOOM_LEVEL - 1))
      } else if (
        selectedLocation?.autocompleteLocation &&
        selectedLocation?.autocompleteLocation.type === 'city'
      ) {
        dispatch(setCurrentMapZoom(LOCATION_ZOOM_LEVEL + 1))
      } else if (
        selectedLocation?.autocompleteLocation &&
        selectedLocation?.autocompleteLocation.type === 'property'
      ) {
        dispatch(setCurrentMapZoom(LOCATION_PROPERTY_ZOOM_LEVEL))
      } else {
        dispatch(setCurrentMapZoom(INITIAL_ZOOM_LEVEL))
      }
    }

    dispatch(setScrollY(0))
    dispatch(setClickedPropertyUid(null))

    if (router.route === searchPage) {
      dispatch(fetchSearchData(1, false, true, router))
      return
    }

    const searchUrl = getSearchUrl(currentLocale)

    //wait to be on search page before loading search result
    router.push(searchUrl, undefined, {shallow: true})
    dispatch(fetchSearchData(1))
  }

  const getContainerClassName = () => {
    if (inTopBar) {
      return clsx(
        classes.root,
        classes.searchMenuContainer,
        classes.headerSearchScroll,
      )
    }

    return clsx(classes.root, classes.searchMenuContainer)
  }

  const getContainerStyle = () => {
    if (isSmallSize) {
      return {
        maxWidth: '100%',
        padding: '0 16px',
      }
    }

    return {}
  }

  return (
    <div className={getContainerClassName()}>
      <div
        className={clsx(classes.flexWrapper, classes.inputAutoComplete)}
        onClick={() => inputLocationRef?.current?.focus()}
        style={getContainerStyle()}
      >
        <IconLocation inTopBar={inTopBar} />
        <LocationSearchBox
          inTopBar={inTopBar}
          onOpen={onOpen}
          defaultSearchValue={defaultSearchValue}
        />
      </div>
      <CustomDivider />
      <div className={classes.searchMenuButtonContainer}>
        <SearchDate inTopBar={inTopBar} onOpen={onOpen} />
      </div>
      <CustomDivider />
      <div className={classes.searchMenuButtonContainer}>
        <GuestsMenu inTopBar={inTopBar} onOpen={onOpen} />
      </div>
      <CustomDivider />
      <Hidden when>
        <AmenitiesSearch inTopBar={inTopBar} />
      </Hidden>

      <AppButton
        variant="contained"
        className={clsx(
          classes.searchPrimaryBtn,
          classes.searchButtonStyle,
          enlargeBtn ? classes.searchLongButtonStyle : '',
        )}
        color="secondary"
        id="search_form_launch"
        onClick={handleSearch}
      >
        <IconSearch inTopBar={inTopBar} />
        {enlargeBtn && <span className="pl-2"> {t('search')}</span>}
      </AppButton>
    </div>
  )
}

function IconLocation(props: {inTopBar?: boolean}) {
  const {inTopBar} = props

  const isSmallSize = useSmallSize()
  const classes = useSearchStyles({inTopBar: inTopBar, isSmallSize})

  if (inTopBar) {
    return null
  }

  return <AppIcon name="location" className={classes.locationIcon} />
}

function IconSearch(props: {inTopBar?: boolean}) {
  if (props.inTopBar) {
    return <AppIcon name="search_other" />
  }

  return <AppIcon name="search" />
}

function AmenitiesSearch(props: {inTopBar?: boolean}) {
  const {inTopBar} = props

  const isSmallSize = useSmallSize()
  const classes = useSearchStyles({inTopBar: inTopBar, isSmallSize})

  if (inTopBar) {
    return null
  }

  return (
    <div className={classes.searchMenuButtonContainer}>
      <SearchAmenities />
    </div>
  )
}

function CustomDivider() {
  const isSmallSize = useSmallSize()
  const isMediumSize = useMediumSize()
  const isLargeSize = useLargeSize()

  const getMargin = () => {
    if (isMediumSize) {
      return '0 8px'
    }

    if (isLargeSize) {
      return '0 20px'
    }

    return '0 8px'
  }

  if (isSmallSize) {
    return null
  }

  return (
    <div
      className="h-[32px] w-px bg-divider"
      style={{
        margin: getMargin(),
      }}
    />
  )
}
