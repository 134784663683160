import {useRef, useEffect} from 'react'
function InnerHTML(props: {html: string}) {
  const {html} = props
  const divRef = useRef<HTMLDivElement | null>(null)

  //from npm install dangerously-set-html-content
  //since dangerouslySetInnerHTML will not render <style, <script, etc
  useEffect(() => {
    if (!divRef.current) return
    const parsedHTML = document.createRange().createContextualFragment(html)
    divRef.current.appendChild(parsedHTML)
  }, [html])

  return <div ref={divRef} />
}

export default InnerHTML
