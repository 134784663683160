import {PageProps} from 'src/types/page'
import {useAppContext} from 'src/context/AppProvider'
import {useEffect, useMemo} from 'react'
import HeadContent from 'src/components/modules/globals/head/HeadContent'
import {PageBreadcrumb} from './PageBreadcrumbs'
import Header from 'src/components/modules/globals/header/Header'
import SearchHero from 'src/components/modules/home/searchHero/SearchHero'
import Footer from 'src/components/modules/globals/footer/Footer'
import {APP_SLUG, SLUG_MC} from 'src/constants/slug'

type Props = {
  page: PageProps
  children: React.ReactNode
  noHero?: boolean
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
}

export default function PageBaseTemplate(props: Props) {
  const {page, noHero = false, maxWidth = 'lg'} = props
  const {setCurrentPageBuilderPage} = useAppContext()

  useEffect(() => {
    setCurrentPageBuilderPage(page)

    return () => {
      setCurrentPageBuilderPage(null)
    }
  }, [page, setCurrentPageBuilderPage])

  const defaultSearchValue = useMemo(() => {
    if (!page) return null
    if (!page.url) return null
    let split: string[] = page.url.split('/')
    if (split.length === 0) return null
    return split.pop() as string
  }, [page])

  const title = APP_SLUG === SLUG_MC ? 'MonsieurChalets' : 'ChaletRetreat'

  const metaTitle = page.meta_title
    ? page.meta_title
    : page.h1_title + (' - ' + title)

  // Use key {page.id} so react dont re-render the same component on page and cause issue
  return (
    <div>
      <HeadContent
        title={metaTitle}
        description={page.meta_description || ''}
        keywords={page.meta_keywords || ''}
        headHtml={page.head_html || ''}
        noindex={!page.indexed}
        imageUrl={page.meta_image?.url as string}
        noHrefLangs
        page={page}
      />
      <Header />
      {!noHero && (
        <SearchHero
          defaultSearchValue={defaultSearchValue}
          maxWidth={maxWidth}
          title={page.h1_title || ''}
          subTitle={page.subtitle || ''}
          breadcrumb={<PageBreadcrumb page={page} />}
        />
      )}
      <div>{props.children}</div>
      <Footer />
    </div>
  )
}
