import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles(
  ({palette, breakpoints, spacing}) => ({
    container: (maxWidth) => ({
      padding: `${spacing(
        22,
        maxWidth ? 0 : 2,
        16.5,
        maxWidth ? 0 : 2,
      )} !important`,
      [breakpoints.down('lg')]: {
        paddingLeft: spacing(3),
        paddingRight: spacing(3),
      },
      [breakpoints.down('sm')]: {
        padding: `${spacing(14, 1.875, 1.875, 1.875)} !important`,
      },
    }),
    justSearchContainer: (maxWidth) => ({
      padding: `${spacing(
        12,
        maxWidth ? 0 : 2,
        0,
        maxWidth ? 0 : 2,
      )} !important`,
    }),
    root: {
      background: `url(/images/seo-bg.jpg);`,
      // maxHeight: spacing(80),
      [breakpoints.up(800)]: {
        height: spacing(80),
      },
      backgroundSize: 'cover',
      [breakpoints.up('md')]: {
        paddingLeft: spacing(2),
        paddingRight: spacing(2),
      },
      [breakpoints.up('xl')]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    breadcrumb: {
      color: palette.common.white,
    },
    desktopSearch: {
      marginTop: 100,
      [breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    mobileSearch: {
      position: 'relative',
      top: 50,
      [breakpoints.up('md')]: {
        display: 'none',
      },
    },
  }),
  {index: 2},
)
